export const getMaxDateOfArray = (dateList) =>
  new Date(Math.max.apply(null, dateList));
export const getMinDateOfArray = (dateList) =>
  new Date(Math.min.apply(null, dateList));
export const addDays = function (date, days) {
  date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
};
export const cloneState = (data) => JSON.parse(JSON.stringify(data));
export const twoDigits = (num) => (("" + num).length < 2 ? "0" + num : num);
export const toDate = (val) => new Date(val);
export const getItalianDate = (date) =>
  `${date.getDate()}/${twoDigits(date.getMonth() + 1)}/${date.getFullYear()}`;
export const getDisplayDate = (originalDate, withoutHour) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const compDate = new Date(originalDate);
  compDate.setHours(0, 0, 0, 0);
  return `${compDate.getTime() === today.getTime()
    ? "oggi"
    : compDate.getTime() === addDays(today, -1).getTime()
      ? "ieri"
      : getItalianDate(originalDate)
    }${withoutHour
      ? ""
      : ` alle ${twoDigits(originalDate.getHours())}:${twoDigits(
        originalDate.getMinutes()
      )}`
    }`;
};
export const zeroHours = (date) => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDisplayHour = (originalDate) => {
  if (!originalDate) {
    return "";
  }
  originalDate = new Date(originalDate);
  return `${twoDigits(originalDate.getHours())}:${twoDigits(
    originalDate.getMinutes()
  )}`;
};
export const redirectAfterUserLogin = (history, user) => {
  const precUrl = sessionStorage.getItem("precUrl");
  if (precUrl) {
    history.push(precUrl);
    sessionStorage.removeItem("precUrl");
  } else {
    history.push(user.type === "ownerReport" ? "/owner/report" : "/owner/dashboard");
  }
};
export const getScrollY = () =>
  window.pageYOffset || document.documentElement.scrollTop;

export const numberToRGB = (colorNum, colors) => {
  if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
  return "hsl(" + ((colorNum * (360 / colors)) % 360) + ",100%,50%)";
};

export const toDateTime = (date) =>
  new Date(date).toISOString().slice(0, 19).replace("T", " ");


export const ottieniGiornoMeseAnno = (dataString) => {
  const data = new Date(dataString);
  // Ottieni giorno, mese e anno
  const giorno = data.getDate();
  const mese = String(data.getMonth() + 1).padStart(2, '0'); // Aggiunge uno zero iniziale se necessario
  const anno = data.getFullYear();
  return (giorno + '/' + mese + '/' + anno).toString();
}

