import React, { useState, useContext } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";
import { DashboardContext } from "../../../contexts/dashboard.context";
import { ReservationContext } from "../../../contexts/reservation.context";
import { getDisplayDate, getMaxDateOfArray, toDate } from "../../../functions";
import DialogMenuMobile from "./owner.room.dialog.menu.mobile"
import axios from 'axios';

import {
    AppBar,
    Button,
    Grid,
    Toolbar,
    TextField
} from "@material-ui/core";


function RoomViewMobile({ tableId, handleClose, table, classes, businessId }) {
    const [isEditing, setIsEditing] = useState(false);
    const [dataMenuObj, setDataMenuObj] = useState({ dataMenu: [], categories: [] })
    const [openDialogMenuMobile, setOpenDialogMenuMobile] = useState(false);
    const { deleteDish, restoreDish, setDish, getDish, modifyTable } = useContext(DashboardContext);
    const { eraseOrder } = useContext(ReservationContext);
    let tableObj, totalPrice, i = -1
    if (table !== null) {
        i = table.reservations.findIndex(({ tableId: id }) => id === tableId);
        tableObj = i === -1 ? null : table.reservations[i];
        totalPrice =
            i === -1
                ? null
                : table.reservations.reduce(
                    (acc, { price, amount, status }) => {
                        if (status !== "owner_deleted") {
                            return acc + price * amount;
                        } else {
                            return acc;
                        }
                    },
                    0
                );
    }





    const handleClickEdit = () => {
        modifyTable(tableObj.id, table);
        setIsEditing(!isEditing);
    };

    const handleChangeDish = (dishId, value) => {
        const [myDish] = getDish(tableId, dishId);
        setDish(tableId, dishId, { ...myDish, ...value });
    };


    const lastUpdate =
        i === -1
            ? null
            : getMaxDateOfArray(
                table.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
            );

    const getCategory = (dishes) => {
        const c = [];
        dishes.forEach(({ categoryId, categoryName }) => {
            const catId = c.map(({ id }) => id);
            if (catId.indexOf(categoryId) === -1) {
                c.push({
                    id: categoryId,
                    name: categoryName,
                });
            }
        });
        return c
    }


    const handleClickOrder = () => {
        // FAI GIRARE (forse)
        if (!openDialogMenuMobile) {
            axios.post('/api/menu/owner/order/get', { businessId }).then(res => {
                let categories = getCategory(res.data.data);
                setDataMenuObj({ dataMenu: res.data.data, categories });
            })
        } else {
            sessionStorage.removeItem('cart');
            eraseOrder();
        }
        setOpenDialogMenuMobile(!openDialogMenuMobile);
    }

    return (
        <div id='roomViewMobile' style={{ overflow: 'scrollable' }}>
            <AppBar style={{ display: 'block' }}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">Tavolo {tableId}</Typography>
                </Toolbar>
            </AppBar>

            <DialogMenuMobile tableId={tableId} openDialog={openDialogMenuMobile} setOpenDialog={setOpenDialogMenuMobile} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} />

            {table !== null ? (
                <Card style={{ marginTop: '50px', boxShadow: 'none' }}>
                    <CardContent>
                        <Grid container style={{ marginBottom: '10px' }}>
                            <Grid item xs={12} >
                                <Typography
                                    gutterBottom
                                    variant="body2"
                                    component="h2"
                                    color="textSecondary"
                                >
                                    Ultima ordinazione: {getDisplayDate(lastUpdate)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {table?.reservations.map(el => (
                            <div key={el.id} style={{ borderBottom: '1px', borderBottomStyle: 'solid', marginBottom: '10px', borderColor: '#b3b3b3', display: 'flex' }}>
                                <div style={{ flex: '1' }}>
                                    <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                        <Grid item>
                                            {isEditing && el.status !== "owner_deleted" ? (
                                                <>
                                                    <TextField type="number" onChange={e => handleChangeDish(el.id, {
                                                        amount: Number(e.target.value)
                                                    })} style={{ width: '50px', marginBottom: '10px' }} value={el.amount} />
                                                    <div style={{ fontWeight: 'bold' }}>{el.name}</div>
                                                </>
                                            ) : <>x{el.status === 'owner_deleted' ? 0 : el.amount} <span style={{ fontWeight: 'bold' }}>{el.name}</span></>}
                                        </Grid>
                                    </Grid>

                                    {el.variant && (
                                        <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                            <Grid item>
                                                {isEditing && el.status !== "owner_deleted" ? (
                                                    <>
                                                        <TextField onChange={e => handleChangeDish(el.id, {
                                                            variant: e.target.value,
                                                        })} value={el.variant} />
                                                    </>
                                                ) : <>con: {el.variant}</>}

                                            </Grid>
                                        </Grid>
                                    )}

                                    {el.note && (
                                        <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                            <Grid item>
                                                note: {el.note}
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                        <Grid style={{ width: '100%', textAlign: 'right' }} item >
                                            {el.price.toFixed(2)} €
                                        </Grid>
                                    </Grid>
                                </div>
                                {isEditing && (el.status === "owner_deleted" ? (
                                    <div>
                                        <IconButton onClick={() => restoreDish(tableId, el.id)}>
                                            <ReplayIcon />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div>
                                        <IconButton onClick={() => deleteDish(tableId, el.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))
                                }
                            </div>

                        ))}
                        <Grid style={{ width: '100%', textAlign: 'right', color: 'green', fontWeight: 'bold', marginTop: '20px', fontSize: '16px' }}>
                            TOTALE: {totalPrice.toFixed(2)} €
                        </Grid>
                    </CardContent>
                    <CardActions>
                        {isEditing ? (
                            <Button onClick={() => handleClickEdit()} variant="contained" color="secondary">TERMINA</Button>
                        ) : (
                            <>
                                <Button onClick={() => setIsEditing(!isEditing)} variant="contained" color="primary">MODIFICA</Button>
                                <Button  onClick={handleClickOrder} variant="contained" color="primary">ORDINA</Button>
                            </>
                        )}

                    </CardActions>
                </Card>
            ) : (
                <Typography style={{ marginTop: '70px', marginLeft: '10px' }} gutterBottom className={classes.dialogContainer}>
                    Nessun ordine
                </Typography>
            )}
        </div >
    )
}

export default RoomViewMobile

