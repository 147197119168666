import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { UserContext } from "./user.context";

const useReservation = () => {
  let { ownerId, tableId } = useParams();
  ownerId = decodeURIComponent(ownerId);
  tableId = decodeURIComponent(tableId);
  const [dishes, setDishes] = useState(
    sessionStorage.getItem("reservationDishes")
      ? JSON.parse(sessionStorage.getItem("reservationDishes"))
      : []
  );
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [ownerIdValid, setownerIdValid] = useState(false);
  const [successOrder, setSuccessOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userHistory, setUserHistory] = useState([]);
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const [cart, setCart] = useState(
    sessionStorage.getItem("cart")
      ? JSON.parse(sessionStorage.getItem("cart"))
      : []
  );
  const { user, eraseUser, userExplicitLogin } = useContext(UserContext);
  const history = useHistory();
  //verify if owner id is valid
  useEffect(() => {
    if (ownerId !== 'undefined') {
      setError(false);
      let subscribed = true;
      axios
        .post("/api/reservation/verify/businessId", { businessId: ownerId })
        .then(({ data }) => {
          if (subscribed) {
            setownerIdValid(data.success);
            if (!data.success) {
              setError("business_id_error");
              setDishes([]);
            }
          }
        });
      return () => (subscribed = false);
    }
  }, [ownerId]);



  useEffect(() => {
    if (user && user.id) {
      const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
        secure: true,
        transports: ["websocket"],
        query: { ownerId: user.id },
      });
      setSocket(s);
    }
  }, [ownerId]);


  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true);
      });
      socket.on("disconnect", () => {
        setConnected(false);
      });
      socket.on("order", order);
      socket.on("eraseOrder", eraseOrder);
      return () => {
        socket.off("order");
        socket.off("eraseOrder");

      };
    }
  }, [socket, isLoading, successOrder]);

  //get owner menu data
  useEffect(() => {
    if (ownerId && dishes.length <= 0 && ownerIdValid) {
      setError(false);
      let subscribed = true;
      axios
        .post("/api/menu/user/get", { businessId: ownerId })
        .then(({ data }) => {
          if (subscribed) {
            if (data.success) {
              setDishes(data.data);
            } else {
              setError("dish_error");
            }
          }
        });
      return () => (subscribed = false);
    }
  }, [ownerId, ownerIdValid, history.location.pathname]);

  //get categories from dishes list
  useEffect(() => {
    const c = [];
    dishes.forEach(({ categoryId, categoryName }) => {
      const catId = c.map(({ id }) => id);
      if (catId.indexOf(categoryId) === -1) {
        c.push({
          id: categoryId,
          name: categoryName,
        });
      }
    });
    setCategories(c);

    if (dishes && dishes.length > 0) {
      sessionStorage.setItem("reservationDishes", JSON.stringify(dishes));
    }
    setIsLoading(false);
  }, [dishes]);


  const mergeDuplicateItems = cart => {
    const groupedItems = {};
  
    cart.forEach((item) => {
      const key = JSON.stringify({
        categoryId: item.categoryId,
        id: item.id,
        variants: item.variants,
        note: item.note,
      });
  
      if (groupedItems[key]) {
        groupedItems[key].amount += item.amount;
      } else {
        groupedItems[key] = { ...item };
      }
    });
  
    const mergedCart = Object.values(groupedItems);
    return mergedCart;
  }
  



  //set cart in session storage
  useEffect(() => {
    if (cart && cart.length > 0) {
      sessionStorage.setItem("cart", JSON.stringify(mergeDuplicateItems(cart)));
    }
  }, [cart]);


  const getSocketId = () => (socket ? socket.id : null);

  //get user history onload
  useEffect(() => {
    if (ownerId || successOrder) {
      setError(false);
      let subscribed = true;

      axios.post("/api/history/user/get", { tableId, ownerId, socketId: getSocketId(), }).then(({ data }) => {
        if (subscribed) {
          if (data.success) {
            setUserHistory(data.history);
          } else {
            setError("history_error");
          }
        }
      });
      return () => (subscribed = false);
    }
  }, [ownerId, successOrder, history.location.pathname]);

  const goReservationAsOwner = (tableEncryptedId, encryptedOwnerId) => {
    // let i = tables.findIndex(({ id }) => tableId === id);
    // if (i !== -1) {
    axios.post("/api/user/sessionId/get").then(({ data }) => {
      if (data.success) {
        sessionStorage.setItem(
          "userCache",
          JSON.stringify({ user, sessionId: data.sessionId })
        );
        eraseUser(true);
        userExplicitLogin(null, true);
        //   let encryptedId = encryptedId;
        history.push(
          `/user/reservation/${encodeURIComponent(
            encryptedOwnerId
          )}/${encodeURIComponent(tableEncryptedId)}`
        );
      }
    });
    // }
  };

  const order = (obj) => {
    setSuccessOrder(true);
    setError(false);
    axios
      .post("/api/reservation/user/order", {
        cart: cart.filter(({ amount }) => amount > 0),
        tableId: tableId !== 'undefined' ? tableId : obj.tableId,
        ownerId: ownerId !== 'undefined' ? ownerId : obj.ownerId,
        isOwner: obj ? true : false,
        socketId: getSocketId()
      })
      .then(({ data }) => {
        if (data.success) {
          setSuccessOrder(false);
          sessionStorage.removeItem("reservationDishes");
          sessionStorage.removeItem("cart");
        } else {
          setError(true);
        }
      });
  };

  const eraseOrder = () => {
    setSuccessOrder(false);
    setError(false);
    setCart([]);
  };

  const addToCart = (dish) => {
    setCart((c) => {
      let cartNew = [...c, dish];
      return mergeDuplicateItems(cartNew)
    });
  };

  const changeAmount = (index, newAmount) => {
    let c = [...cart];
    if (newAmount === 0) {
      removeFromCart(index);
      return;
    }
    c[index].amount = newAmount;
    setCart(c);
  };

  const removeFromCart = (index) => {
    setCart((c) => {
      c = c.filter((_, i) => i !== index);
      sessionStorage.setItem("cart", JSON.stringify(c));
      return c;
    });
  };

  const setDishNote = (index, text) => {
    let c = [...cart];
    c[index] = { ...c[index], note: text };
    setCart(c);
  };

  const getDishPrice = (dish) => {
    let sum = dish.price;
    if (dish.variants) {
      for (let x of dish.variants) {
        if (Array.isArray(x.selected)) {
          for (let y of x.selected) {
            sum += y.price;
          }
        } else {
          sum += x.selected.price;
        }
      }
    }
    return sum;
  };

/*   const getDishPrice = (dish) => {
    let sum = dish.price;
    if (dish.variants) {
      sum += dish.variants.reduce(
        (acc, { selected }) => acc + selected.price,
        0
      );
    }
    return sum;
  }; */


  const getTotalPrice = () => {
    return cart
      .filter(({ amount }) => amount > 0)
      .reduce((acc, dish) => acc + getDishPrice(dish) * dish.amount, 0);
  }


  return {
    dishes,
    categories,
    addToCart,
    setDishes,
    order,
    getTotalPrice,
    error,
    successOrder,
    isLoading,
    eraseOrder,
    userHistory,
    setUserHistory,
    tableId,
    ownerId,
    setSocket,
    getDishPrice,
    cart,
    setCart,
    changeAmount,
    removeFromCart,
    setDishNote,
    goReservationAsOwner,
  };
};

export const ReservationContext = createContext();

export const ReservationProvider = (props) => {
  const reservation = useReservation();

  return (
    <>
      <ReservationContext.Provider value={reservation}>
        {props.children}
      </ReservationContext.Provider>
    </>
  );
};
