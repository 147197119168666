import { TableCell, TableRow, TextField, ButtonGroup, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { DashboardContext } from "../../../contexts/dashboard.context";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {
  NumberFormatEuro,
  NumberFormatInteger,
} from "../../customComponents/number.format.input";

const styles = (theme) => ({
  dialogContainer: {
    minWidth: 300,
  },
  dialogContent: { marginTop: theme.spacing(8) },
  deletedDish: {
    textDecoration: "line-through",
  },
  spacingLeft: {
    marginLeft: theme.spacing(2),
  },
  iconPadding: {
    padding: 4,
  },
});

export default withStyles(styles)(function RoomDishRow({
  dish,
  classes,
  tableId,
  isEditing,
  handleChangeTotalPrice
}) {
  const {setDish, getDish, modifyTable, data, getTable, setTable } =
    useContext(DashboardContext);
  const [amount, setAmount] = useState(dish.amount);

  const i = data.findIndex(({ tableId: id }) => id === tableId);
  const table = i === -1 ? null : data[i];

  const handleChangeDish = (dishId, value) => {
    const [myDish] = getDish(tableId, dishId);
    setDish(tableId, dishId, { ...myDish, ...value });
  };

  const handleChangeAmount = (dishId, value) => {
    setAmount(value);
    const [myDish] = getDish(tableId, dishId);
    myDish.amount = value;
    setDish(tableId, dishId, { ...myDish });
    handleChangeTotalPrice(undefined, 'dishRow');

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 3000);

  };


  const deleteItem = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "owner_deleted";
        setTable(tableId, { ...myTable, reservations: r });
      }
    }

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 1000);
  };

  const restoreItem = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "preparation";

        setTable(tableId, { ...myTable, reservations: r });
      }
    }

    setTimeout(() => {
      modifyTable(table.id, table);
    }, 1000);
  };

  return (
    <TableRow>
      <TableCell scope="row">
        <div className={dish.status === "owner_deleted" ? classes.deletedDish : ""}>
          {dish.status !== "owner_deleted" ? (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => handleChangeAmount(dish.id, amount - 1)}
                className={classes.iconPadding}
                disabled={amount > 1 ? false : true}
              >
                <RemoveIcon fontSize="inherit" />
              </Button>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
              >
                {amount}
              </Button>
              <Button
                aria-label="delete"
                variant="outlined"
                onClick={() => handleChangeAmount(dish.id, amount + 1)}
                className={classes.iconPadding}
              //disabled={!dish.id}
              >
                <AddIcon fontSize="inherit" />
              </Button>
            </ButtonGroup>
          ) : (
            0
          )
          }
        </div>



        {/* <div
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {isEditing && dish.status !== "owner_deleted" ? (
            <TextField
              label="Quantità"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.spacingBottom}
              name="amount"
              InputProps={{
                inputComponent: NumberFormatInteger,
              }}
              value={dish.amount}
              onChange={(e) =>
                handleChangeDish(dish.id, {
                  amount: Number(e.target.value),
                })
              }
            />
          ) : dish.status === "owner_deleted" ? (
            0
          ) : (
            dish.amount
          )}
        </div> */}
      </TableCell>
      <TableCell>
        <Typography
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {dish.name}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <div
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {isEditing && dish.status !== "owner_deleted" ? (
            <TextField
              label="Variante"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.spacingBottom}
              name="variant"
              value={dish.variant || ''}
              onChange={(e) =>
                handleChangeDish(dish.id, {
                  variant: e.target.value,
                })
              }
            />
          ) : (
            dish.variant
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <Typography
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {dish.note}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <div
          className={dish.status === "owner_deleted" ? classes.deletedDish : ""}
        >
          {isEditing && dish.status !== "owner_deleted" ? (
            <TextField
              label="Prezzo"
              fullWidth
              margin="normal"
              variant="outlined"
              className={classes.spacingBottom}
              name="price"
              InputProps={{
                inputComponent: NumberFormatEuro,
              }}
              value={dish.price}
              onChange={(e) =>
                handleChangeDish(dish.id, {
                  price: Number(e.target.value),
                })
              }
            />
          ) : (
            <>{dish.price !== null ? `${dish.price.toFixed(2)} €` : "€"}</>
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        {
          (dish.status === "owner_deleted" ? (
            <IconButton onClick={() => restoreItem(tableId, dish.id)}>
              <ReplayIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => deleteItem(tableId, dish.id)}>
              <DeleteIcon />
            </IconButton>
          ))}
      </TableCell>
    </TableRow>
  );
});
