import React, { useState, useRef } from 'react';
import { Dialog, Button, DialogTitle, Grid, Typography, RadioGroup, Radio, FormControlLabel, IconButton, useMediaQuery, Checkbox, FormGroup, FormControl, FormLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
    },
    gridItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
})); 


function OwnerRoomDialogVariants({ setDialogVariants, dialogVariants, addToCart }) {
    const classes = useStyles();
    const [variantsSelection, setVariantsSelection] = useState([]);
    const [variantsError, setVariantsError] = useState(false);
    const [isMultiSelection, setIsMultiSelection] = useState(Boolean(0));
    const errorMsg = useRef();

    const isMobile = useMediaQuery("(max-width:576px)");

    const getOption = (variantId, optionId) => {
        if (dialogVariants.data.variants && dialogVariants.data.variants.length !== 0) {
            let index = dialogVariants.data.variants.findIndex(({ id }) => id === variantId);
            if (index !== -1) {
                let i = dialogVariants.data.variants[index].options.findIndex(({ id }) => id === optionId);
                if (i !== -1) {
                    return dialogVariants.data.variants[index].options[i];
                }
            }
        }
    };

    const removeSelected = (idValueTarget) => {
        let _variantSelection = [...variantsSelection];
        let isFilter = false; // Abbiamo l'id del Selected
        for (let x of _variantSelection) { // Entro nel variants
            for (let y of x.selected) { // Entro nel selected del variants
                if (y.id === idValueTarget) {
                    y.id = 'to delete'
                    isFilter = true;
                    break;
                }
            }
            if (isFilter) {
                _variantSelection = _variantSelection.filter(el => {
                    for (let z of el.selected) {
                        // Ritorno tutti gli elementi nel variantSelection
                        // tranne quelli che nel selected.id ha il valore 'to delete'
                        if (z.id != 'to delete') return el;
                    }
                });
                isFilter = false;
            }

        }
        return _variantSelection;
    };

    /**
     * Questa funzione ti permette di trovare se nelle variants ci sono i multi (0 o 1);
     * Se trova un mix restituisce true
     * Se non trova un minx e quindi sono o tutti multi o tutti single restituisce false
     */
    function checkConsistentMulti(variants) {
        // Ottieni il valore di multi del primo elemento
        const firstMulti = variants[0].multi;
        // Verifica se tutti gli altri elementi hanno lo stesso valore di multi del primo elemento
        for (let i = 1; i < variants.length; i++) {
            if (variants[i].multi !== firstMulti) {
                return false; // Se almeno un elemento ha un valore di multi diverso, restituisci false
            }
        }
        return true; // Se tutti gli elementi hanno lo stesso valore di multi, restituisci true

    }

    const handleChangeVariant = (e, variantId) => {
        let updatedVariants = [...dialogVariants.data.variants];
        let index = updatedVariants.findIndex(({ id }) => id === variantId);
        const _checkConsistentMulti = checkConsistentMulti(updatedVariants);

        if (!_checkConsistentMulti) {

            // Se l'elemento è già presente nelle varianti selezionate, rimuovilo
            let updatedSelection = [...variantsSelection];

            // Aggiungi l'elemento selezionato solo se la checkbox è spuntata

            if (e.target.checked) {
                if (e.target.type === 'checkbox') {
                    updatedSelection.push({
                        ...updatedVariants[index],
                        id: variantId,
                        selected: [{ ...getOption(variantId, e.target.value) }]
                    });
                } else {
                    // Se è un radio, sostituisci il vecchio valore con il nuovo
                    const indexElse = updatedSelection.map(el => el.id).indexOf(variantId);
                    if (indexElse === -1) {
                        updatedSelection.push({
                            ...updatedVariants[index],
                            id: variantId,
                            selected: [{ ...getOption(variantId, e.target.value) }]
                        });
                    } else {
                        updatedSelection[indexElse] = {
                            ...updatedSelection[indexElse],
                            ...updatedVariants[index],
                            selected: [{ ...getOption(variantId, e.target.value) }]
                        }
                    }
                }
            } else {
                updatedSelection = removeSelected(e.target.value)
            }
            const removeDuplicate = new Set([...updatedSelection]);
            const transformToArray = Array.from(removeDuplicate);

            setVariantsSelection(transformToArray);

        } else {
            if (e.target.type === 'checkbox') {
                if (e.target.checked) {
                    updatedVariants[index] = {
                        ...updatedVariants[index],
                        selected: [{ ...getOption(variantId, e.target.value) }]
                    };
                    setVariantsSelection([...variantsSelection, updatedVariants[index]]);
                } else setVariantsSelection(removeSelected(e.target.value));
            } else {
                updatedVariants[index] = {
                    ...updatedVariants[index],
                    selected: getOption(variantId, e.target.value)
                };
                setDialogVariants(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        variants: updatedVariants
                    }
                }));
                setVariantsSelection(updatedVariants);
            }
        }
    };


    //se sei multi (radio e checkbox) deve scattare l'errore solo sul radio, quindi va a vedere se i selected ci sono dei radio button
    function checkRadioRequired(dialogVariants, variantsSelection) {
        for (const variant of dialogVariants) {
            if (variant.multi === 0) {
                const selectedVariant = variantsSelection.find(selection => selection.id === variant.id);
                if (!selectedVariant) {
                    return true; // Opzione mancante trovata
                }
            }
        }
        return false; // Nessuna opzione mancante trovata
    }


    const handleConfirm = () => {

        let sonoMulti = !checkConsistentMulti(dialogVariants.data.variants)
        if (sonoMulti) {

            let radioMancante = checkRadioRequired(dialogVariants.data.variants, variantsSelection)
            if (radioMancante) {
                setVariantsError(true);
                setIsMultiSelection(Boolean(0)); // Questo perché non deve visualizzare l'errore dei checkbox quando sono multi
                errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                return;
            }

        } else {
            if (variantsSelection.length === 0) {
                setVariantsError(true);
                setIsMultiSelection(Boolean(dialogVariants.data.variants[0].multi)); // Solo se tutto il dialog ha la selezione multi.
                errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                return;
            } else {
                for (let x of variantsSelection) {
                    if (x.multi === 0 && !x.hasOwnProperty('selected')) {
                        setVariantsError(true);
                        errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                        return;
                    }
                }
            }
        }

        addToCart({
            ...dialogVariants.data,
            amount: 1,
            note: "",
            variants: variantsSelection,
        });
        setVariantsSelection([]);
        setVariantsError(false);
        setDialogVariants({ isOpen: false, data: {} })

    }

    const onCloseDialog = () => {
        setDialogVariants({ isOpen: false, data: {} });
        setVariantsSelection([]);
        setVariantsError(false);
    }


    return (
        <Dialog stlye={{ position: 'relative' }} fullWidth={true} maxWidth="xl" open={dialogVariants.isOpen} id="error">

            <DialogTitle ref={errorMsg} className={classes.dialogTitle}>
                <IconButton
                    style={{ position: 'absolute', left: '20px', top: '10px' }}
                    edge="start"
                    color="inherit"
                    onClick={() => onCloseDialog()}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                {dialogVariants.data.name}
            </DialogTitle>

            <FormLabel component="legend" style={{ textAlign: 'center', margin: '10px 0' }} >
                {(variantsError && isMultiSelection) && (
                    <Typography variant="body1" color="secondary">
                        Selezionare almeno una categoria
                    </Typography>
                )}
            </FormLabel>

            <Grid container>
                {dialogVariants.data.variants?.map((el, index) => (
                    <Grid key={index} item md={!isMobile ? Math.floor(12 / dialogVariants.data.variants.length) : 12} className={classes.gridItem}>
                        <Typography variant="h5">{el.name}</Typography>

                        <FormControl onChange={(e) => handleChangeVariant(e, el.id)}>

                            {el.multi ? (
                                <FormGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={el.id}
                                >

                                    {el.options.map(({ id, label, price }) => (
                                        <FormControlLabel
                                            key={id}
                                            value={id}
                                            control={<Checkbox />}
                                            label={
                                                <>
                                                    <Typography variant="inherit">
                                                        {label}{" "}
                                                        <Typography
                                                            variant="overline"
                                                            color="textSecondary"
                                                        >
                                                            +{price.toFixed(2)}€
                                                        </Typography>
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    ))}
                                </FormGroup>
                            ) : (
                                <>
                                    <FormLabel component="legend">
                                        <Typography color="textPrimary" variant="h5">
                                            {variantsError && (
                                                <Typography variant="body2" color="secondary">
                                                    (scelta obbligatoria)
                                                </Typography>
                                            )}
                                        </Typography>
                                    </FormLabel>

                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                    >
                                        {el.options.map(({ id, label, price }) => (
                                            <FormControlLabel
                                                key={id}
                                                value={id}
                                                control={<Radio />}
                                                label={
                                                    <>
                                                        <Typography variant="inherit">
                                                            {label}{" "}
                                                            <Typography
                                                                variant="overline"
                                                                color="textSecondary"
                                                            >
                                                                +{price.toFixed(2)}€
                                                            </Typography>
                                                        </Typography>
                                                    </>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </>
                            )}

                        </FormControl>
                    </Grid>
                ))}
            </Grid>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleConfirm}
            >
                Aggiungi al carrello
            </Button>
        </Dialog>
    );

}
export default OwnerRoomDialogVariants;

