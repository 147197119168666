import { makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
	draggable: {
		position: 'absolute',
		left: ({ coords }) => coords.x,
		top: ({ coords }) => coords.y,
	},
	grabCursor: {
		cursor: `grab`,
		'&:active': {
			cursor: `grabbing`,
		},
	},
}));

export default function Draggable({
	children,
	containerRef,
	draggingClassName,
	onDragStart,
	onDragOver,
	onDragEnter,
	onDragEnd,
	startingCoords,
	disableDrag
}) {
	const [delta, setDelta] = useState();
	const [coords, setCoords] = useState(startingCoords || { x: 0, y: 0 });
	const [scrollCoords, setScrollCoords] = useState({ x: 0, y: 0 });

	const classes = useStyles({ coords });
	const ref = useRef();

	const getCurrentScrollCoords = () => ({
		y: containerRef.current.scrollTop,
		x: containerRef.current.scrollLeft,
	});

	const onDragStartDef = (event, ...rest) => {
		if (!disableDrag) {
			const style = window.getComputedStyle(event.target, null);
			if (event?.type === 'touchstart') {
				setDelta({
					x: parseInt(style.getPropertyValue('left'), 10) - event.touches[0].pageX,
					y: parseInt(style.getPropertyValue('top'), 10) - event.touches[0].pageY,
				})
			} else {
				setDelta({
					x: parseInt(style.getPropertyValue('left'), 10) - event.clientX,
					y: parseInt(style.getPropertyValue('top'), 10) - event.clientY,
				});
			}

			setScrollCoords(getCurrentScrollCoords());
			ref.current.classList.add(draggingClassName);

			//hide original node and show only ghost node
			setTimeout(function () {
				ref.current.style.visibility = 'hidden';
			}, 1);

			if (onDragStart) {
				onDragStart({ ...event, coords }, ...rest);
			}
		}
	};

	const onDragOverDef = (event, ...rest) => {
		event.stopPropagation();
		event.preventDefault();
		if (onDragOver) {
			onDragOver({ ...event, coords }, ...rest);
		}
	};

	const onDragEnterDef = (event, ...rest) => {
		event.stopPropagation();
		event.preventDefault();
		if (onDragEnter) {
			onDragEnter({ ...event, coords }, ...rest);
		}
	};

	const onDragEndDef = (event, ...rest) => {
		if (!disableDrag) {
			// if (event?.type !== 'touchend') 
			//event.preventDefault();
			let draggableBcr = ref.current.getBoundingClientRect();
			let containerBcr = containerRef.current.getBoundingClientRect();
			const curScrollCoords = getCurrentScrollCoords();
			const scrollDelta = {
				x: curScrollCoords.x - scrollCoords.x,
				y: curScrollCoords.y - scrollCoords.y,
			};

			if (event?.type === 'touchend') {
				// Gestisci la fine del tocco
				if (event.changedTouches && event.changedTouches.length > 0) {
					const touch = event.changedTouches[0];
					event.clientX = touch.pageX;
					event.clientY = touch.pageY;

				}
			}
			let newCoords = {
				x: event.clientX + parseInt(delta.x, 10) + scrollDelta.x,
				y: event.clientY + parseInt(delta.y, 10) + scrollDelta.y,
			};

			// check if draggable item is dropped outer of the container bounds -> place item at the edge of the screen if this happens
			if (event.clientX - draggableBcr.width / 2 < containerBcr.left) {
				newCoords.x = scrollCoords.x;
			}
			if (event.clientY - draggableBcr.height / 2 < containerBcr.top) {
				newCoords.y = scrollCoords.y;
			}

			if (event.clientX + draggableBcr.width / 2 > containerBcr.width) {
				newCoords.x = scrollCoords.x + containerBcr.width - draggableBcr.width;
			}
			if (event.clientY - draggableBcr.height > containerBcr.height) {
				newCoords.y = scrollCoords.y + containerBcr.height - draggableBcr.height;
			}

			setCoords(newCoords);
			ref.current.classList.remove(draggingClassName);

			//show original node again because ghost node is gone
			setTimeout(function () {
				ref.current.style.visibility = '';
			}, 1);

			if (onDragEnd) {
				onDragEnd({ ...event, coords: newCoords }, ...rest);
			}
			document.querySelector('#container-draggable').style.overflow = 'auto';
			document.querySelector('html').style.overflow = 'auto';
		}
	};

	const hiddenOverflow = () => {
		if (!disableDrag) {
			document.querySelector('#container-draggable').style.overflow = 'hidden';
			document.querySelector('html').style.overflow = 'hidden';
		}
	}

	return React.Children.only(
		React.cloneElement(children, {
			draggable: !disableDrag,
			className: `${children.props.className} ${classes.draggable} ${!disableDrag ? classes.grabCursor : ''
				}`,
			onDragStart: onDragStartDef,
			onDragOver: onDragOverDef,
			onDragEnter: onDragEnterDef,
			onDragEnd: onDragEndDef,
			onTouchStart: onDragStartDef,
			onTouchMove: hiddenOverflow,
			onTouchEnd: onDragEndDef,
			ref,
		})
	);
}
