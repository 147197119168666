import { Badge, Collapse, Fab, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../contexts/dashboard.context";

const useStyles = makeStyles((theme) => ({
  listItemGrid: {
    paddingTop: 20,
  },
  spacingBottom: {
    marginBottom: theme.spacing(2),
  },
  centeredText: {
    left: 0,
    lineHeight: "200px",
    marginTop: "-100px",
    position: "absolute",
    textAlign: "center",
    top: "50%",
    width: "100%",
    userSelect: "none",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(2),
    zIndex: 1401,  //per qualche motivo lo snackbar ha zindex 1400
  },
  notif: {
    position: "fixed",
    bottom: theme.spacing(12),
    right: theme.spacing(2),
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 280,
  },
  snackbar: {
    bottom: theme.spacing(10),
    top: "auto",
    zIndex: -1,
  },

  "@keyframes ring": {
    "0%": { transform: "rotate(0) scale(1)" },
    "4%": { transform: "rotate(30deg)  scale(1.1280000000000001)" },
    "8%": { transform: "rotate(-28deg) scale(1.256)" },
    "12%": { transform: "rotate(34deg) scale(1.384)" },
    "16%": { transform: "rotate(-32deg) scale(1.384)" },
    "20%": { transform: "rotate(30deg) scale(1.256)" },
    "24%": { transform: "rotate(-28deg) scale(1.1280000000000001)" },
    "28%": { transform: "rotate(26deg) scale(1)" },
    "32%": { transform: "rotate(-24deg) " },
    "36%": { transform: "rotate(22deg) " },
    "40%": { transform: "rotate(-20deg) " },
    "44%": { transform: "rotate(18deg) " },
    "48%": { transform: "rotate(-16deg) " },
    "52%": { transform: "rotate(14deg) " },
    "56%": { transform: "rotate(-12deg)" },
    "60%": { transform: "rotate(10deg)" },
    "64%": { transform: "rotate(-8deg)" },
    "68%": { transform: "rotate(6deg)" },
    "72%": { transform: "rotate(-4deg)" },
    "76%": { transform: "rotate(2deg)" },
    "80%": { transform: "rotate(-1deg)" },
    "84%": { transform: "rotate(1deg)" },
    "88%": { transform: "rotate(0)" },
    "100%": { transform: "rotate(0)" },
  },
  ringBell: {
    animation: "$ring 1s ",
  },
}));

function DashboardNotification({ isClickedOut, setIsClickedOut }) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { data, setData, ringingBell, setRingingBell, discardCalledTable } =
    useContext(DashboardContext);
  const [calledTables, setcalledTables] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const onClickDismiss = useCallback(
    (k) => {
      let i = calledTables.findIndex(({ key }) => key === k);
      if (i !== -1) {
        let id = calledTables[i].tableId;
        discardCalledTable(id);
        setData((d) => {
          let j = d.findIndex(({ tableId }) => id === tableId);
          if (j !== -1) {
            d[j].isCalled = true;
          }
          return d;
        });
      }
      closeSnackbar(k);
    },
    [data, calledTables]
  );

  const displayNotification = useCallback(
    (tableId) =>
      enqueueSnackbar(`Il Tavolo ${tableId} richiede il cameriere`, {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        persist: true,
        TransitionComponent: Collapse,
        action: (key) => (
          <IconButton color="secondary" onClick={() => onClickDismiss(key)}>
            <ClearIcon />
          </IconButton>
        ),
      }),
    [onClickDismiss]
  );

  useEffect(() => {
    if (isClickedOut) {
      setShowAll(false);
      setIsClickedOut(false);
    }
  }, [isClickedOut]);

  useEffect(() => {
    let precCalledTables = [...calledTables];
    const newCalledTables = data.filter(({ isCalled }) => isCalled);

    //discard excedded notification
    if (precCalledTables.length > newCalledTables.length) {
      const newIds = newCalledTables.map(({ tableId }) => tableId);
      precCalledTables = precCalledTables.filter(
        ({ tableId }) => newIds.indexOf(tableId) === -1
      );
      const precIds = precCalledTables.map(({ tableId }) => tableId);
      const indexes = precIds.map((id) => precIds.indexOf(id));
      indexes.forEach((i) => {
        closeSnackbar(precCalledTables[i].key);
      });
    }
    setcalledTables(newCalledTables);
  }, [data]);

  useEffect(() => {
    if (calledTables.length === 0) {
      setShowAll(false);
    }
  }, [calledTables]);

  useEffect(() => {
    let stateToUpdate = false;
    let c = [...calledTables];
    if (showAll) {
      c.map((val) => {
        if (!val.key) {
          val.key = displayNotification(val.tableId);
          stateToUpdate = true;
        }
        return val;
      });
    } else {
      c.map((val) => {
        if (val.key) {
          closeSnackbar(val.key);
          val.key = null;
          stateToUpdate = true;
        }
        return val;
      });
    }
    if (stateToUpdate) {
      setcalledTables(c);
    }
  }, [showAll, calledTables, displayNotification]);

  useEffect(() => {
    if (!showAll) {
      setRingingBell(false);
    }
  }, [showAll]);

  const handleClickShowAll = () => {
    setShowAll((s) => !s);
  };

  return (
    <>
      <Fab
        className={classes.fab}
        onClick={handleClickShowAll}
        disabled={calledTables.length === 0}
      >
        <Badge
          badgeContent={calledTables.length}
          color="secondary"
          overlap="rectangular"
        >
          {showAll ? (
            <ArrowDownwardIcon />
          ) : (
            <NotificationsActiveIcon
              className={ringingBell ? classes.ringBell : ""}
              onAnimationEnd={() => setRingingBell(false)}
            />
          )}
        </Badge>
      </Fab>
    </>
  );
}

const NotistackWrapper = (props) => {
  const notistackRef = React.createRef();

  const classes = useStyles();

  return (
    <SnackbarProvider
      classes={{
        root: classes.snackbar,
      }}
      maxSnack={Infinity}
      ref={notistackRef}
    >
      <DashboardNotification {...props} />
    </SnackbarProvider>
  );
};

export default NotistackWrapper;
