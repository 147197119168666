import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Container, Button, Box, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		// padding: theme.spacing(2, 4, 3),
		outline: 'none',
		minHeight: '500px',
		borderRadius: 4,
	},
	largeWidth: {
		minWidth: 400,
		maxWidth: 700,
	},
	content: {
		outline: 'none',
		boxShadow: theme.shadows[5],
	},
	spacingTop: {
		marginTop: theme.spacing(2),
	},
	closeButton: {
		marginLeft: 'auto',
		float: 'right',
		fontSize: 24,
	},
	fullScreen: {
		height: '95vh',
		width: '89vw',
	},
}));

export default function EventDetailsModal({ open, handleClose, children, ...props }) {
	const classes = useStyles();

	const screenIsSmall = useMediaQuery('(max-width:635px)'); //used for table text size

	return (
		<div>
			<Modal
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box className={classes.content}>
						<Button color='secondary' className={classes.closeButton} onClick={handleClose}>
							&times;
						</Button>
						<div className={`${classes.paper} ${screenIsSmall ? classes.fullScreen : classes.largeWidth}`}>
							<Container>{children}</Container>
						</div>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
}
