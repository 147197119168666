import { Button, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { ReservationContext } from '../../../contexts/reservation.context';
import Dish from '../../user/cart/user.cart.dish';
import { OwnerRoomTableContext } from './owner.room.table';


const useStyles = makeStyles((theme) => ({
    spacingTop: {
        marginTop: theme.spacing(2),
    },
    spacingBottom: {
        marginBottom: theme.spacing(2),
    },
    content: {
        marginRight: theme.spacing(1),
        padding:'10px'
    },
    centeredText: {
        marginTop: '60px',
        textAlign: 'center',
    },
}));

const UserCart = ({ handleClickOrder, getAmountTotal }) => {
    const classes = useStyles();
    const { cart, getTotalPrice, successOrder, order } =
        useContext(ReservationContext);
    const [totalPrice, setTotalPrice] = useState(getTotalPrice());
    const _OwnerRoomTableContext = useContext(OwnerRoomTableContext);

    useEffect(() => {
        setTotalPrice(getTotalPrice());
        if (getAmountTotal) getAmountTotal(cart); //? Perché nella parte mobile, quando si clicca su "Ordina" viene richiamata questa funzione (non si sa perché!)
    }, [cart]);

    const _handleOrder = () => {
        order(_OwnerRoomTableContext);
        handleClickOrder();
    }


    return (
        <>
            <div className={classes.content}>
                {cart &&
                    cart.length !== 0 &&
                    cart.map((dish, i) => (
                        <React.Fragment key={dish.id + '_' + i + '_' + dish.amount + '_' + dish.note}>
                            <Dish dish={dish} dishIndex={i} />
                            <Divider className={classes.spacingTop} />
                        </React.Fragment>
                    ))}
                {cart && cart.length !== 0 ? (
                    <Button
                        variant='contained'
                        color='secondary'
                        fullWidth
                        className={`${classes.spacingTop} ${classes.spacingBottom}`}
                        onClick={() => _handleOrder()}
                        disabled={successOrder}
                    >
                        Ordina {totalPrice.toFixed(2)}€
                    </Button>
                ) :
                    (
                        <Typography
                            variant='subtitle1'
                            color='textSecondary'
                            className={classes.centeredText}
                        >
                            Il carrello è vuoto.
                        </Typography>
                    )
                }
            </div>
        </>
    );
};

export default UserCart;
