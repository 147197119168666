import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";

export default function ConfirmDialog({
  text,
  handleClose: onClose,
  open,
  successTimeout,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccess = () => {
    setIsLoading(true);
    if (successTimeout) {
      setTimeout(() => {
        handleClose(true);
        setIsLoading(false);
      }, successTimeout);
    } else {
      handleClose(true);
      setIsLoading(false);
    }

  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose(event);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>{text.text}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleClose(false)}
            color="secondary"
          >
            Annulla
          </Button>
          <Button onClick={handleSuccess} color="primary" autoFocus>
            {isLoading ? <CircularProgress size={24} /> : "Conferma"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
