import { Badge, Fab, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getScrollY } from "../../functions";

const FabScroll = ({ text, icon, badgeProps, iconProps, ...rest }) => {
  const [showText, setShowText] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(getScrollY());

  useEffect(() => {
    const checkScrollDirection = () => {
      var st = getScrollY();
      setShowText(st <= lastScrollTop); // scrolling up: st<=lastScrollTop (scrolled to max top is getScrollY()=0)
      setLastScrollTop(st <= 0 ? 0 : st); ///normalize negative Y to 0 (can be negative for mobile)
    };
    window.addEventListener("scroll", checkScrollDirection);
    return () => window.removeEventListener("scroll", checkScrollDirection);
  });

  return (
    <Fab variant={showText ? "extended" : "circular"} {...rest}>
      <Badge color="secondary" overlap="rectangular" {...badgeProps}>
        {React.createElement(icon, iconProps)}
      </Badge>

      {showText ? <Typography variant="button">&nbsp;{text}</Typography> : ""}
    </Fab>
  );
};

export default FabScroll;
