import React, { useContext, useState } from 'react'
import CardActions from '@material-ui/core/CardActions';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DashboardContext } from "../../../contexts/dashboard.context";
import { UserContext } from '../../../contexts/user.context';
import { getDisplayDate, getMaxDateOfArray, toDate } from "../../../functions";
import "./printBill.css";

import {
    AppBar,
    Button,
    Grid,
    Toolbar
} from "@material-ui/core";
import axios from 'axios';


function RoomViewPrint({ tableId, handleClose, table, statesSconti }) {
    const { removeTable } = useContext(DashboardContext);
    const { user } = useContext(UserContext);

    let totalPrice, i = -1, mergedReservations;
    if (table !== null) {
        const groupedReservations = {};
        table.reservations.forEach((reservations) => {
            const key = `${reservations.tableId}-${reservations.variant}-${reservations.note}-${reservations.dishId}`;
            if (groupedReservations[key]) {
                // Duplicato trovato, aumenta la quantità
                groupedReservations[key].amount += reservations.amount;
            } else {
                // Non duplicato, aggiungi una nuova voce nell'oggetto raggruppato
                groupedReservations[key] = { ...reservations };
            }
        });
        // Converti l'oggetto raggruppato in un array
        mergedReservations = Object.values(groupedReservations);
        // Aggiorna il campo "reservations" con i nuovi dati

        i = mergedReservations.findIndex(({ tableId: id }) => id === tableId);
        totalPrice = i === -1 ? null : mergedReservations.reduce(
            (acc, { price, amount, status }) => {
                if (status !== "owner_deleted") {
                    return acc + price * amount;
                } else {
                    return acc;
                }
            },
            0
        );

    }

    const handleClickPrintRemove = () => {
        //avvia stampa
        window.print();
        axios.post('/api/dashboard/owner/table/history', { table: table, totaleOrdine: totalPrice, totalePagato: statesSconti.nuovoTotalPrice !== 0 ? statesSconti.nuovoTotalPrice : totalPrice }).then(() => {
            handleClose();
            removeTable(table.tableId, false);
        })


    }

    const lastUpdate =
        i === -1
            ? null
            : getMaxDateOfArray(
                mergedReservations.map(({ lastUpdate }) => toDate(lastUpdate))
            );

    return (
        <div id="scontrino" style={{ fontSize: '20px', backgroundColor: '#fff' }} >
            <Typography style={{ marginBottom: '15px', color: '#000', fontSize: '16px' }}>Non valida come ricevuta fiscale</Typography>
            <AppBar id="appBar_Mobile" style={{ display: 'block' }}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Typography style={{ marginBottom: '10px', textAlign:'center', borderBottom:'1px solid black', borderTop: '1px solid black' }} variant="h3">Rues45</Typography> */}

                    <Typography id="titolo_mobile" variant="h5">{user.name} - Tavolo {tableId}</Typography>
                </Toolbar>
            </AppBar>

            <div style={{ boxShadow: 'none', color: '#000' }}>
                <div>
                    {mergedReservations?.map(el => (
                        <div key={el.id}>
                            {el.status !== 'owner_deleted' &&
                                <div style={{ borderBottom: '1px', borderBottomStyle: 'solid', marginBottom: '10px', borderColor: '#b3b3b3', display: 'flex' }}>
                                    <div style={{ flex: '1' }}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <span style={{ fontWeight: 'bold' }}>x{el.amount} {el.name}</span>
                                            </Grid>
                                        </Grid>

                                        {el.variant && (
                                            <Grid container spacing={2} >
                                                <Grid item>
                                                    con: {el.variant}
                                                </Grid>
                                            </Grid>
                                        )}

                                        {el.note && (
                                            <Grid container spacing={2} >
                                                <Grid item>
                                                    note: {el.note}
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid container spacing={2} >
                                            <Grid style={{ width: '100%', textAlign: 'right' }} item >
                                                {el.price && el.amount ? (el.price * el.amount).toFixed(2) : 0} €
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            }
                        </div>

                    ))}
                    <Grid style={statesSconti.scontoTotalPrice !== 0 ? {textDecoration:'line-through', width: '100%', textAlign: 'right', fontWeight: 'bold', marginTop: '20px' } : {width: '100%', textAlign: 'right', fontWeight: 'bold', marginTop: '20px' }}>
                        TOTALE: {totalPrice ? totalPrice.toFixed(2) : 0} €
                    </Grid>
                    {statesSconti.scontoTotalPrice !== 0 ? (
                        <>
                            <Grid style={{ width: '100%', textAlign: 'right', fontWeight: 'bold', marginTop: '20px' }}>
                                SCONTO DI: {statesSconti.scontoTotalPrice.toFixed(2)} €
                            </Grid>
                            <Grid style={{ width: '100%', textAlign: 'right', fontWeight: 'bold', marginTop: '20px' }}>
                                NUOVO TOTALE: {statesSconti.nuovoTotalPrice.toFixed(2)} €
                            </Grid>
                        </>
                    ) : (<></>)}

                    <Grid container style={{ margin: '20px 0', textAlign: 'center' }}>
                        <Grid item xs={12} >
                            <Typography
                                gutterBottom
                                variant="body2"
                                component="h2"
                                style={{ color: '#000' }}
                            >
                                Ultima ordinazione: {getDisplayDate(lastUpdate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <CardActions id="button_Mobile">
                    <Button onClick={handleClickPrintRemove} variant="contained" color="primary">Stampa e Elimina</Button>
                </CardActions>
            </div>

        </div >
    )
}

export default RoomViewPrint

