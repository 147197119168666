import {
  AppBar,
  Button,
  Grid,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  TextField
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useState } from "react";
import axios from 'axios';
import { DashboardContext } from "../../../contexts/dashboard.context";
import { ReservationContext } from "../../../contexts/reservation.context";
import { getDisplayDate, getMaxDateOfArray, toDate } from "../../../functions";
import OwnerRoomDishRow from "./owner.room.dish.row";
import RoomViewMobile from "./owner.room.dialog.view.mobile";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RoomViewPrint from './owner.room.dialog.view.print';
import { NumberFormatFloat } from "../../customComponents/number.format.input";
import DialogMenu from './owner.room.dialog.menu'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from "@material-ui/icons/Remove";
//import "./printBill.css";

const styles = (theme) => ({
  dialogContainer: {
    minWidth: 300,
  },
  dialogContent: { marginTop: theme.spacing(8) },
  deletedDish: {
    textDecoration: "line-through",
  },
  spacingLeft: {
    marginLeft: theme.spacing(2),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default withStyles(styles)(function RoomDialogView({
  classes,
  openDialogView: open,
  setOpenDialogView: setOpen,
  tableId,
  businessId,
}) {
  const { data, modifyTable, removeTable } = useContext(DashboardContext);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialogPrintMobile, setOpenDialogPrintMobile] = useState(false);
  const [scontoTotalPrice, setScontoTotalPrice] = useState(0);
  const [nuovoTotalPrice, setNuovoTotalPrice] = useState(0);
  const [openDialogMenu, setOpenDialogMenu] = useState(false);
  const [dataMenuObj, setDataMenuObj] = useState({ dataMenu: [], categories: [] })
  const { eraseOrder } = useContext(ReservationContext);
  const i = data.findIndex(({ tableId: id }) => id === tableId);
  const table = i === -1 ? null : data[i];
  const isMobile = useMediaQuery("(max-width:576px)");

  const totalPrice =
    i === -1
      ? null
      : table.reservations.reduce(
        (acc, { price, amount, status }) => {
          if (status !== "owner_deleted") {
            return acc + price * amount;
          } else {
            return acc;
          }
        },
        0
      );


  const lastUpdate =
    i === -1
      ? null
      : getMaxDateOfArray(
        table.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
      );

  const handleClose = () => {
    setOpen(false);
    setOpenDialogPrintMobile(false);
  };

  const handleClickEdit = () => {
    modifyTable(table.id, table);
    setIsEditing((s) => !s);
  };

  const handleClickPrintRemove = () => {
    //avvia stampa
    openDialogMobile();
  }

  const handleClickRemove = () => {
    removeTable(tableId, false);
  }

  const handleChangeTotalPrice = (sconto, refComponent) => {
    if (refComponent === 'dishRow') {
      setScontoTotalPrice(0)
    } else {
      setScontoTotalPrice(Number(sconto));
      const scontoApplicato = totalPrice - sconto;
      setNuovoTotalPrice(Number(scontoApplicato));
    }

  }

  const openDialogMobile = () => {
    setOpenDialogPrintMobile(!openDialogPrintMobile)
  }

  const getCategory = (dishes) => {
    const c = [];
    dishes.forEach(({ categoryId, categoryName }) => {
      const catId = c.map(({ id }) => id);
      if (catId.indexOf(categoryId) === -1) {
        c.push({
          id: categoryId,
          name: categoryName,
        });
      }
    });
    return c
  }


  const handleClickOrder = () => {
    // FAI GIRARE (forse)
    if (!openDialogMenu) {
      axios.post('/api/menu/owner/order/get', { businessId }).then(res => {
        let categories = getCategory(res.data.data);
        setDataMenuObj({ dataMenu: res.data.data, categories });

      })
    } else {
      sessionStorage.removeItem('cart');
      eraseOrder();
    }
    setOpenDialogMenu(!openDialogMenu);
  }


  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        fullScreen
        TransitionComponent={Transition}
      >
        {!isMobile ? (
          <MuiDialogContent dividers className={classes.dialogContent}>
            <AppBar>
              <Toolbar >
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6">Tavolo {tableId}</Typography>
              </Toolbar>
            </AppBar>

            {/** VERSIONE STAMPABILE*/}
            <Dialog onClose={openDialogMobile} open={openDialogPrintMobile} fullScreen TransitionComponent={Transition}>
              <MuiDialogContent dividers className={classes.dialogContent}>
                <RoomViewPrint handleClose={handleClose} classes={classes} tableId={tableId} table={table} statesSconti={{ nuovoTotalPrice, scontoTotalPrice }} />
              </MuiDialogContent>
            </Dialog>
            {/** VERSIONE STAMPABILE*/}

            <DialogMenu tableId={tableId} openDialog={openDialogMenu} setOpenDialog={setOpenDialogMenu} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} />

            {table && table.reservations.length !== 0 ? (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="h2"
                        color="textSecondary"
                      >
                        Ultima ordinazione: {getDisplayDate(lastUpdate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <TableContainer>
                  <Table className={classes.table} aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Quantità</TableCell>
                        <TableCell>Nome</TableCell>
                        <TableCell align="right">Variante</TableCell>
                        <TableCell align="right" >Note</TableCell>
                        <TableCell align="right">Prezzo</TableCell>
                        <TableCell align="right">
                          {isEditing && "Azioni"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table.reservations.map((dish) => (
                        <OwnerRoomDishRow
                          key={dish.id}
                          dish={dish}
                          classes={classes}
                          tableId={tableId}
                          isEditing={isEditing}
                          handleChangeTotalPrice={handleChangeTotalPrice}
                        />
                      ))}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={4}
                          align="right"
                        >
                          <Typography color="primary">TOTALE</Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography color="primary">
                            {totalPrice ? totalPrice.toFixed(2) : 0} €
                          </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={3}
                          align="right"
                        >

                        </TableCell>

                        {scontoTotalPrice < 0.5 ? (<TableCell align="right"></TableCell>) : (
                          <TableCell align="right">
                            <Button onClick={() => setScontoTotalPrice(scontoTotalPrice - 0.5)}>
                              <RemoveIcon fontSize='large' color='primary' />
                            </Button>
                          </TableCell>
                        )}


                        <TableCell align="center" >

                          <TextField
                            style={{ width: '75px' }}
                            label="Sconto"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={scontoTotalPrice}
                            className={classes.spacingBottom}
                            InputProps={{
                              inputComponent: NumberFormatFloat,
                            }}
                            onChange={(e) =>
                              handleChangeTotalPrice(e.target.value)
                            }>

                          </TextField>

                        </TableCell>

                        {scontoTotalPrice >= totalPrice ? (<TableCell align="right"></TableCell>) : (
                          <TableCell align="left">
                            <Button onClick={() => setScontoTotalPrice(scontoTotalPrice + 0.5)}>
                              <AddIcon fontSize='large' color='primary' />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>

                      {scontoTotalPrice !== 0 ? (
                        <>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={4}
                              align="right"
                            >
                              <Typography color="primary">NUOVO TOTALE</Typography>
                            </TableCell>

                            <TableCell align="right">
                              <Typography color="primary">{nuovoTotalPrice.toFixed(2) + ' €'}</Typography>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>


                        </>
                      ) : (<></>)}

                      <TableRow>
                        {!isEditing ? (
                          <>
                            <TableCell component="th" scope="row" align="left">
                              <Button
                                style={{ marginRight: "20px" }}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOrder}
                              >
                                Ordina
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickEdit}
                              >
                                Modifica
                              </Button>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={4}
                              align="right"
                            >
                              {totalPrice > 0 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickPrintRemove}
                                >
                                  vai alla stampa
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={handleClickRemove}
                                >
                                  elimina ordini
                                </Button>
                              )}

                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={6}
                              align="right"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClickEdit}
                              >
                                Termina
                              </Button>
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          <Typography color="primary"></Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Typography gutterBottom className={classes.dialogContainer}>
                Nessun ordine
              </Typography>
            )}
          </MuiDialogContent>
        )
          : <RoomViewMobile businessId={businessId} handleClose={handleClose} classes={classes} tableId={tableId} /* data={data}  */ table={table} />
        }
      </Dialog>
    </div>
  );
});
