import {
	Box,
	Button,
	ButtonGroup,
	Container,
	FormControlLabel,
	makeStyles,
	Switch,
	useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import OwnerRoomDialog from './owner.room.dialog';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(10),
		display: 'flex',
		flexDirection: 'row',
		'&:after': {
			content: '" "',
			display: 'block',
			flex: 1,
		},
	},
	containerChildLeft: {
		flex: 1,
		textAlign: 'left',
	},
	containerChildRight: {
		flex: 1,
		textAlign: 'center',
		alignSelf: 'center',
	},
	fullWidthList: { width: '100%' },
	dashedDivider: { borderBottom: `1px dashed ${theme.palette.divider}` },
	addRoomButton: {
		marginRight: theme.spacing(3),
	},
}));

export default function OwnerRoomButtonBar({
	rooms,
	setRooms,
	setIsLoading,
	setCurrentRoom,
	currentRoom,
	setTables,
	isLocked,
	setIsLocked,
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const isMobile = useMediaQuery("(max-width:576px)");

	useEffect(() => {
		if (!rooms) {
			let subscribed = true;
			setIsLoading(true);
			axios.post('/api/rooms/owner/get').then(({ data }) => {
				if (subscribed) {
					if (!data || !data.success) {
						//error
						setRooms([]);
						setIsLoading(false);
					} else {
						//success
						setRooms(data.roomsList);
						setIsLoading(false);
						if (data.roomsList.length !== 0) {
							setCurrentRoom(data.roomsList[0].id);
						}
					}
				}
			});

			return () => (subscribed = false);
		}
	}, [rooms, setCurrentRoom, setIsLoading, setRooms]);

	const updateCurrentRoom = (id) => {
		setCurrentRoom(id);
		setTables(null);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<Box className={classes.container}>
			{!isMobile ? (
				<Container className={classes.containerChildLeft}>
					<Button
						className={classes.addRoomButton}
						variant='contained'
						color='primary'
						onClick={handleClickOpen}
					>
						Gestisci sale
					</Button>

					<FormControlLabel
						control={
							<Switch
								checked={!isLocked}
								onChange={(e) => setIsLocked(!e.target.checked)}
								color='primary'
							/>
						}
						label='Gestisci tavoli'
					/>
				</Container>
			) : <></>}

			<Container className={classes.containerChildRight}>
				<ButtonGroup>
					{rooms &&
						rooms.map(({ id, name }) => (
							<Button
								key={id}
								onClick={() => {
									updateCurrentRoom(id);
								}}
								color={id === currentRoom ? 'primary' : 'default'}
								variant='contained'
							>
								{name} 
							</Button>
						))}
				</ButtonGroup>
			</Container>

			<OwnerRoomDialog
				rooms={rooms}
				setRooms={setRooms}
				open={open}
				setOpen={setOpen}
				setCurrentRoom={setCurrentRoom}
				currentRoom={currentRoom}
			/>
		</Box>
	);
}
