import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { Fragment, useContext, useRef, useState } from "react";
import { ReservationContext } from "../../../contexts/reservation.context";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 4,
    width: "100%",
  },
  media: {
    height: 140,
  },
  iconPadding: {
    padding: 4,
  },
  noPaddingBottom: { paddingBottom: 0 },
  checkbox: {
    margin: 0,
    padding: 0,
    float: "right",
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
}));

const UserCartDish = ({ dish, dishIndex }) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(dish.amount);
  const [showNote, setShowNote] = useState(!!dish.note);
  const [showRemove, setShowRemove] = useState(true);
  const noteRef = useRef();
  const { getDishPrice, removeFromCart, setDishNote, changeAmount } =
    useContext(ReservationContext);

  const handleChangeAmount = (value) => {
    setAmount(value);
    changeAmount(dishIndex, value);
  };

  const handleClickRemove = () => {
    removeFromCart(dishIndex);
  };

  const handleClickAddNote = () => {
    setShowNote(true);
  };

  const handleBlurNote = () => {
    setShowRemove(true);
    saveNote();
  };

  const handleFocusNote = () => {
    setShowRemove(false);
  };

  const handleClickRemoveNote = () => {
    setShowNote(false);
    removeNote();
  };

  const saveNote = () => {
    setDishNote(dishIndex, noteRef.current.value);
  };

  const removeNote = () => {
    setDishNote(dishIndex, null);
  };

  const getDashVariants = () => {
    const variantsArr = [];
    for (let x of dish.variants) {
      if (Array.isArray(x.selected)) {
        for (let y of x.selected) {
          variantsArr.push(y.label);
        }
      } else variantsArr.push(x.selected.label);
    }
    return variantsArr.join(' - ');
  }

  return (
    <>
      <Grid container>
        <Grid item xs={9} className={classes.spacingTop}>
          <Typography variant="subtitle1">{dish.name}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.spacingTop}>
          {getDishPrice(dish) && getDishPrice(dish) !== 0 ? (
            <Typography>{getDishPrice(dish).toFixed(2)}</Typography>
          ) : (
            ""
          )}
        </Grid>

        {dish.variants && dish.variants.length !== 0 && (
          <Grid item xs={12}>
            <Typography color="textSecondary">
              {getDashVariants()}
            </Typography>
          </Grid>
        )}
        {showNote && (
          <>
            <Grid item xs={11} className={classes.spacingTop}>
              <TextField
                id="outlined-multiline-flexible"
                placeholder={"Note"}
                multiline
                maxRows={4}
                defaultValue={dish.note}
                fullWidth
                onBlur={handleBlurNote}
                onFocus={handleFocusNote}
                inputRef={noteRef}
              />
            </Grid>
            <Grid item xs={1} className={classes.spacingTop}>
              <IconButton
                onClick={showRemove ? handleClickRemoveNote : saveNote}
              >
                {showRemove ? (
                  <CloseIcon color="secondary" />
                ) : (
                  <CheckIcon color="primary" />
                )}
              </IconButton>
            </Grid>
          </>
        )}
        {!showNote && (
          <>
            <Grid item xs={12} className={classes.spacingTop}>
              <Button
                aria-label="delete"
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClickAddNote}
                className={classes.iconPadding}
              >
                Aggiungi nota
              </Button>
            </Grid>
          </>
        )}

        <Grid item xs={7} className={classes.spacingTop}>
          <ButtonGroup size="small" aria-label="small outlined button group">
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount - 1)}
              className={classes.iconPadding}
              disabled={!dish.id || amount < 2}
            >
              <RemoveIcon fontSize="inherit" />

            </Button>
            <Button
              disableElevation
              disableFocusRipple
              disableRipple
              disabled={!dish.id}
            >
              {amount}
            </Button>
            <Button
              aria-label="delete"
              variant="outlined"
              onClick={() => handleChangeAmount(amount + 1)}
              className={classes.iconPadding}
              disabled={!dish.id}
            >
              <AddIcon fontSize="inherit" />
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={5} className={classes.spacingTop}>
          <Button
            aria-label="delete"
            variant="outlined"
            color="secondary"
            size="small"
            className={classes.iconPadding}
            fullWidth
            onClick={handleClickRemove}
          >
            Rimuovi
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default UserCartDish;
