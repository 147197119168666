import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { UserContext } from './user.context';

const useChat = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useContext(UserContext);
	const [socket, setSocket] = useState(null);
	const [connected, setConnected] = useState(false);
	let { ownerId, tableId } = useParams();
	const [enabled, setEnabled] = useState(null);

	ownerId = decodeURIComponent(ownerId);
	tableId = decodeURIComponent(tableId);
	const [chat, setChat] = useState([]);

	//connect to socket
	useEffect(() => {
		if (user && user.id) {
			const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
				secure: true,
				transports: ['websocket'],
				query: { ownerId, type: 'user' },
			});
			setSocket(s);
		}
	}, [user]);

	useEffect(() => {
		axios.post('/api/user/chat/get', { ownerId }).then(({ data }) => {
			setEnabled(data.chatEnabled);
		})
	}, [ownerId]);

	//manage real time events
	useEffect(() => {
		if (socket) {
			socket.on('connect', () => {
				setConnected(true);
			});
			socket.on('disconnect', () => {
				setConnected(false);
			});
			socket.on('recieveMessage', recieveMessage);
			socket.on('syncMessages', syncMessages);
			socket.on('disableChat', disableChat);
			socket.on('enableChat', enableChat);

			return () => {
				socket.off('disableChat');
				socket.off('connect');
				socket.off('disconnect');
				socket.off('recieveMessage');
				socket.off('syncMessages');
			};
		}
	}, [socket, isLoading]);

	const enableChat = () => setEnabled(true);

	const disableChat = () => {
		setChat([]);
		setEnabled(false);
	};

	const syncMessages = (msgs) => {
		setIsLoading(false);
		if (msgs) {
			setChat(msgs);
		}
	};

	const recieveMessage = (msg) => {
		setChat((c) => [...c, msg]);
	};

	const sendMessage = (text) => {
		socket.emit('sendMessage', { ownerId, tableId, text });
		setChat((c) => [...c, { tableId: null, text, time: Date.now() }]);
	};

	return {
		isLoading,
		sendMessage,
		enabled,
		chat,
		connected,
	};
};

export const ChatContext = createContext();

export const ChatProvider = (props) => {
	const dashboard = useChat();

	return (
		<>
			<ChatContext.Provider value={dashboard}>{props.children}</ChatContext.Provider>
		</>
	);
};
