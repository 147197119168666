import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	spacingTop: { marginTop: theme.spacing(2) },
}));

export default function AlertCollapse({ show, severity, title, text, className, id }) {
	const classes = useStyles();

	return (
		<Collapse timeout={1000} in={show} className={`${className} ${classes.spacingTop}`} id={id}>
			<Alert severity={severity || 'error'}>
				{title && <AlertTitle>{title}</AlertTitle>}
				{text}
			</Alert>
		</Collapse>
	);
}
