import { CssBaseline, responsiveFontSizes } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { lazy, useMemo } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import OwnerRoom from "./components/owner/room/owner.room";
import { ChatProvider } from "./contexts/chat.context";
import { DashboardProvider } from "./contexts/dashboard.context";
import { ReservationProvider } from "./contexts/reservation.context";
import { UserProvider } from "./contexts/user.context";
  
const CookieConsent = lazy(() =>
  import("./components/customComponents/cookie.consent")
); 


const FullScreenError = lazy(() =>
  import("./components/customComponents/fullscreen.error")
); 
const PrivateRoute = lazy(() =>
  import("./components/customComponents/private.route")
);
const Route = lazy(() => import("./components/customComponents/route.title"));
const AppBar = lazy(() => import("./components/owner/appBar/owner.app.bar"));
const ConfirmMail = lazy(() =>
  import("./components/owner/confirmMail/owner.confirm.mail")
);
const OwnerDashboard = lazy(() =>
  import("./components/owner/dashboard/owner.dashboard")
);

const OwnerReport = lazy(() =>
  import("./components/owner/report/owner.report")
);

const OwnerMenu = lazy(() => import("./components/owner/menu/owner.menu"));
const OwnerProfile = lazy(() =>
  import("./components/owner/profile/owner.profile")
);
const OwnerSignup = lazy(() =>
  import("./components/owner/signup/owner.signup")
);
const OwnerTables = lazy(() =>
  import("./components/owner/tables/owner.tables")
);
const ResetPassword = lazy(() =>
  import("./components/restorePassword/restore.password")
);
const Landing = lazy(() => import("./landing/landing.jsx"));
const Terms = lazy(() => import("./landing/terms"));
const Privacy = lazy(() => import("./landing/privacy"));
const UserSignup = lazy(() => import("./components/user/signup/user.signup"));
const UserBottomNavigation = lazy(() =>
  import("./components/user/bottomNavigation/user.bottom.navigation")
);
const SignIn = lazy(() => import("./components/signin"));
const RestorePassword = lazy(() =>
  import("./components/restorePassword/restore.password.request")
);
const OwnerReservation = lazy(() =>
  import("./components/owner/reservation/reservation")
);

//place here global styles to apply in every component
const globalStyles = {
  "@global": {
    //hide default show password button for ms-edge
    "input::-ms-reveal": {
      display: "none",
    },
    "input::-ms-clear": {
      display: "none",
    },
    //custom scrollbar
    "body::-webkit-scrollbar": {
      width: "10px",
      height: 8,
    },
    "body::-webkit-scrollbar-track": {
      boxAhadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    },
    "body::-webkit-scrollbar-thumb": {
      backgroundColor: "#43a047",
      outline: "1px solid slategrey",
    },
    body: {
      //firefox
      "scrollbar-width": "thin",
      "scrollbar-color": "#43a047",
    },
  },
};
 
 
const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(
    () =>
      responsiveFontSizes(
        //manages responsive font size automatically
        createTheme({
          overrides: {
            MuiCircularProgress: {
              colorPrimary: {
                color: "white",
              },
            },
          },

          palette: {
            type: "dark",
            primary: {
              main: green[600],
              contrastText: "#fff",
            },
            secondary: {
              main: red[600],
              contrastText: "#fff",
            },
            background: {
              //paper: "#424242" : "#e8e8e8",
              paper:"#424242"
            },
          },
        })
      ),
    [prefersDarkMode]
  );

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <CookieConsent
          cookieName="cookieConsent"
          message="Questo sito utilizza cookie tecnici e di terze parti. Proseguendo nella navigazione accetti l’utilizzo dei cookie."
          acceptButtonLabel="Chiudi"
        />
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/privacy" title="Informativa privacy">
              <Privacy />
            </Route>
            <Route exact path="/terms" title="Termini e condizioni">
              <Terms />
            </Route>
            <Route exact path="/signin" title="Accedi">
              <SignIn />
            </Route>

            {/* ********************* USER *****************/}
            <Route exact path="/user/signup" title="Registrati">
              <UserSignup />
            </Route>
            <Route
              exact
              path="/user/restorePassword/:requestId/:userId"
              title="Recupero password"
            >
              <ResetPassword />
            </Route>
            <Route exact path="/user/restorePassword" title="Recupero password">
              <RestorePassword />
            </Route>
            <PrivateRoute exact path="/user/home">
              <Landing logged={true} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/reservation/:ownerId/:tableId"
              title="Ordina"
            >
              <ReservationProvider>
                <ChatProvider>
                  <UserBottomNavigation section={0} />
                </ChatProvider>
              </ReservationProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/history/:ownerId/:tableId"
              title="Ordina"
            >
              <ReservationProvider>
                <ChatProvider>
                  <UserBottomNavigation section={1} />
                </ChatProvider>
              </ReservationProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/chat/:ownerId/:tableId"
              title="Chat"
            >
              <ReservationProvider>
                <ChatProvider>
                  <UserBottomNavigation section={2} />
                </ChatProvider>
              </ReservationProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/user/cart/:ownerId/:tableId"
              title="Ordina"
            >
              <ReservationProvider>
                <ChatProvider>
                  <UserBottomNavigation section={3} />
                </ChatProvider>
              </ReservationProvider>
            </PrivateRoute>

            {/* ********************* OWNER *****************/}
            <Route exact path="/owner/signup" title="Registrati">
              <OwnerSignup />
            </Route>
            <Route
              exact
              path="/owner/restorePassword/:requestId/:userId"
              title="Recupero password"
            >
              <ResetPassword />
            </Route>
            <Route
              exact
              path="/owner/restorePassword"
              title="Recupero password"
            >
              <RestorePassword />
            </Route>
            <Route
              exact
              path="/owner/confirmEmail/:token"
              title="Conferma e-mail"
            >
              <ConfirmMail />
            </Route>
            <PrivateRoute exact path="/owner/profile" title="Profilo">
              <AppBar>
                <OwnerProfile />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/menu" title="Menu">
              <AppBar>
                <OwnerMenu />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/tables" title="Tavoli">
              <AppBar>
                <OwnerTables />
              </AppBar>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/room" title="Sala">
              <DashboardProvider>
                <ReservationProvider>
                  <AppBar>
                    <OwnerRoom />
                  </AppBar>
                </ReservationProvider>
              </DashboardProvider>
            </PrivateRoute>
            <PrivateRoute exact path="/owner/dashboard" title="Dashboard">
              <DashboardProvider excludePrinted={true}>
                <AppBar>
                  <OwnerDashboard />
                </AppBar>
              </DashboardProvider>
            </PrivateRoute>

            <PrivateRoute exact path="/owner/report" title="Report">
              <DashboardProvider>
                <AppBar>
                  <OwnerReport />
                </AppBar>
              </DashboardProvider>
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/owner/reservation/:ownerId"
              title="Ordina"
            >
              <ReservationProvider>
                <AppBar>
                  <OwnerReservation />
                </AppBar>
              </ReservationProvider>
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/owner/reservation/:ownerId/:tableId"
              title="Ordina"
            >
              <ReservationProvider>
                <AppBar>
                  <OwnerReservation />
                </AppBar>
              </ReservationProvider>
            </PrivateRoute>

            {/* ********************* ERROR 404 *****************/}
            <Route path="*">
              <FullScreenError name="404" text="Pagina non trovata" />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
};

export default withStyles(globalStyles)(App);
