import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export default function ComponentLoader({ children, isLoading, spacing, ...rest }) {
	return isLoading ? (
		<CenteredSpinner spacing={spacing} />
	) : (
		React.Children.toArray(children).map((child) => React.cloneElement(child, rest))
	);
}

export function CenteredSpinner({ spacing }) {
	const useStyles = makeStyles((theme) => ({
		customSpacing: spacing
			? {
					marginTop: theme.spacing(spacing),
					marginBottom: theme.spacing(spacing),
			  }
			: {},
		fullScreen: {
			position: 'fixed',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
		},
	}));

	const classes = useStyles();
	return (
		<center className={spacing ? classes.customSpacing : classes.fullScreen}>
			<CircularProgress />
		</center>
	);
}
