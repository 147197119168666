import { Button, Grid, TextField, Select, MenuItem, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import AlertCollapse from '../../customComponents/alert.collapse';
import FabScroll from '../../customComponents/fab.scroll';
import Modal from '../../customComponents/modal';
import {
    NumberFormatEuro,
} from "../../customComponents/number.format.input";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    fixedTabs: { position: 'fixed', top: 48, zIndex: 2, width: '96vw' },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(6),
        left: theme.spacing(1),
    },

}));

export default function OutMenu({
    dishes,
    setDishes,
    setShowOutMenu,
    showOutMenu,
}) {
    const classes = useStyles();

    const [error, setError] = useState(false);
    const [dataOutMenu, setDataOutMenu] = useState({
        priceMenu: null,
        selectMenu: 'seleziona',
        descriptMenu: '',
        amountMenu: 1
    })

    const handleCloseOutMenu = () => {
        setError(false);
        setShowOutMenu(false);
    };

    const handleConfirmOutMenu = () => {

        if (dataOutMenu.descriptMenu.length === 0) {
            setError('Descrivi la richiesta');
            return;
        }

        if (dataOutMenu.priceMenu === null) {
            setError('Il prezzo non può essere vuoto');
            return;
        }

        if (dataOutMenu.selectMenu === 'seleziona') {
            setError('Selezionare la tipologia');
            return;
        }


        if (dataOutMenu.descriptMenu && dataOutMenu.priceMenu && dataOutMenu.selectMenu !== 'seleziona') {
            if (dataOutMenu.descriptMenu.length > 150) {
                setError('La descrizione non può superare i 150 caratteri.');
                return;
            }

            setDishes([
                ...dishes,
                {
                    id: null,
                    name: dataOutMenu.descriptMenu,
                    price: parseFloat(dataOutMenu.priceMenu),
                    amount: dataOutMenu.amountMenu,
                    note: '',
                    selected_option: dataOutMenu.selectMenu,
                    categoryId: 'out_menu',
                    categoryName: 'Fuori menu',
                },
            ]);

            setDataOutMenu({
                priceMenu: null,
                selectMenu: 'seleziona',
                descriptMenu: '',
                amountMenu: 1
            })
            handleCloseOutMenu();
        }
    };

    return (
        <>
            <Modal open={showOutMenu} handleClose={handleCloseOutMenu}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <AlertCollapse
                            text={
                                typeof error === 'string'
                                    && error
                            }
                            show={!!error}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label='Descrivi la tua richiesta fuori menu'
                            multiline
                            minRows={10}
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            onChange={e => setDataOutMenu({ ...dataOutMenu, descriptMenu: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField onChange={e => setDataOutMenu({ ...dataOutMenu, priceMenu: e.target.value })} label="Prezzo" variant="outlined" InputProps={{
                            inputComponent: NumberFormatEuro,
                        }} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{float:'right'}}>
                            <Select onChange={e => setDataOutMenu({ ...dataOutMenu, selectMenu: e.target.value })} defaultValue={'seleziona'}>
                                <MenuItem disabled value='seleziona'>SELEZIONA</MenuItem>
                                <MenuItem value='bar'>Bar</MenuItem>
                                <MenuItem value='cucina'>Cucina</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color='secondary'
                            variant='contained'
                            fullWidth
                            onClick={handleCloseOutMenu}
                        >
                            Annulla
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color='primary'
                            variant='contained'
                            fullWidth
                            onClick={handleConfirmOutMenu}
                        >
                            Conferma
                        </Button>
                    </Grid>
                </Grid>
            </Modal>

            <FabScroll
                aria-label='out-menu'
                className={classes.fab}
                onClick={() => setShowOutMenu(true)}
                icon={AddIcon}
                text={'Fuori menu'}
                size='small'
            />

        </>
    );
}
