import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { UserContext } from "./user.context";
import notificationSoundFile from "../../../client/src/notification/Melodico.mp3";
import notificationSoundNewOrder from "../../../client/src/notification/Tesoro.mp3";
import { Redirect, useLocation } from "react-router-dom";

const useDashboard = (excludePrinted) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [connected, setConnected] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    text: "",
    severity: "",
    timeout: null,
    isfirstConnectionDone: false,
    noCloseButton: true,
  });
  const [lastGeneralUpdate, setLastGeneralUpdate] = useState(new Date()); //last time tables were fatched
  const [ringingBell, setRingingBell] = useState(false);

  const location = useLocation();

  //reset state every path change
  useEffect(() => {
    const unlisten = history.listen(() => {
      setData([]);
    });
    return () => unlisten();
  });

  //onload: fetch data from server on every path change
  useEffect(() => {

    if (connected) {
      let subscribed = true;
      setIsLoading(true);
      let filter = localStorage.getItem('filterRes')
      axios
        .post(
          `/api/dashboard/owner/get${excludePrinted ? `?excludePrinted=true` : ""
          }`, { filter }
        )
        .then(({ data }) => {
          if (subscribed) {
            if (!data || !data.success) {
              //error
              setIsLoading(false);
              setError(data.error || true);
            } else {
              //success
              data.reservations.forEach(({ tableId, isCalled }) => {
                if (isCalled) {
                  callTable({ tableId, currentData: data.reservations });
                }
              });
              setIsLoading(false);

              setData(data.reservations);
              setLastGeneralUpdate(new Date());
            }
          }
        });
      return () => (subscribed = false);
    }
  }, [connected, history.location.pathname]);

  //connect to socket
  useEffect(() => {
    if (user && user.id) {
      const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
        secure: true,
        transports: ["websocket"],
        query: { ownerId: user.id },
      });
      setSocket(s);
    }
  }, [user]);

  //manage real time events
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true);
      });
      socket.on("disconnect", () => {
        setConnected(false);
      });
      socket.on("updateTable", updateTable);
      socket.on("removeTable", removeTable);
      socket.on("calledService", callTable);
      socket.on("discardNotification", discardNotification);
      socket.on("modifyTable", modifyTable);

      return () => {
        socket.off("modifyTable");
        socket.off("updateTable");
        socket.off("removeTable");
        socket.off("connect");
        socket.off("disconnect");
        socket.off("calledService");
        socket.off("discardNotification");
      };
    }
  }, [socket, data, isLoading]);

  //manage connection lost notification
  useEffect(() => {
    if (!notification.isfirstConnectionDone) {
      if (connected) {
        setNotification({ ...notification, isfirstConnectionDone: true });
      }
      return;
    }
    if (!connected) {
      setNotification({
        ...notification,
        text: "Connessione persa, tentativo di riconnessione...",
        severity: "warning",
        open: true,
        timeout: null,
      });
    } else {
      setNotification({
        ...notification,
        text: "Connessione ristabilita.",
        severity: "success",
        open: true,
        timeout: undefined,
      });
    }
  }, [connected]);

  const getSocketId = () => (socket ? socket.id : null);

  const getTable = (tableId) => {
    let d = [...data];
    const myTableIndex = d.map(({ tableId }) => tableId).indexOf(tableId);
    if (myTableIndex !== -1) {
      let myTable = d[myTableIndex];
      return [myTable, myTableIndex];
    }
    return [null, -1];
  };

  const _resToggleFilter = (res) => {
    setData(res.data.reservations);
  }

  const setTable = (tableId, value) => {
    let d = [...data];
    const [_, myTableIndex] = getTable(tableId);
    if (myTableIndex !== -1) {
      d[myTableIndex] = value;
      setData(d);
      setLastGeneralUpdate(new Date());
    }
  };

  const getDish = (tableId, dishId) => {
    let [myTable] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let myDish = myTable.reservations[myDishIndex];
        return [myDish, myDishIndex];
      }
    }

    return [null, -1];
  };

  const setDish = (tableId, dishId, value) => {
    let d = [...data];
    let [myTable, myTableIndex] = getTable(tableId);
    let [_, myDishIndex] = getDish(tableId, dishId);
    myTable.reservations[myDishIndex] = value;
    d[myTableIndex] = myTable;
    setData(d);
    setLastGeneralUpdate(new Date());
  };

  const deleteDish = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "owner_deleted";
        setTable(tableId, { ...myTable, reservations: r });
      }
    }
  };

  const restoreDish = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "preparation";

        setTable(tableId, { ...myTable, reservations: r });
      }
    }
  };
  const callTable = ({ tableId, currentData }) => {
    currentData = currentData ?? data;

    const i = currentData.findIndex(({ tableId: t }) => t === tableId);
    if (i !== -1) {
      let d = [...currentData];
      d[i].isCalled = true;
      setData(d);
      setRingingBell(true);
      if (history.location.pathname !== '/owner/report') {
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play();
      }
    } else {
      let d = [
        ...currentData,
        { tableId, isCalled: true, reservations: [], isLoading: false },
      ];
      setData(d);
      setRingingBell(true);
      if (history.location.pathname !== '/owner/report') {
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play();
      }
    }
  };


  /*
   La funzione getNewReservation serve per avere un nuovo tavolo perché la funzione getTable può restituire "data" ma con zero reservations 
   Esempio
    [
      {
          "tableId": "1",
          "isModifing": false,
          "reservations": [],
          "isLoading": false
      }
    ]
   creo la funzione per avere un campo di appoggio per farlo suonare
   Modificando getTable si rompe la disposizione dei tavoli 
  */
  const getNewReservation = (tableId) => {
    let x = [...data];
    let d = x.filter(el => el.reservations.length != 0);
    const newTableIndex = d.map(({ tableId }) => tableId).indexOf(tableId);
    if (newTableIndex !== -1) {
      return newTableIndex;
    }
    return -1;
  };

  const updateTable = (tableId) => {
    let [myTable, myTableIndex] = getTable(tableId);
    let newTableIndex = getNewReservation(tableId);
    if (myTableIndex !== -1) {
      //table already exists -> set loading
      setTable(tableId, { ...myTable, isLoading: true });
    }

    axios
      .post(`/api/dashboard/owner/table/get${excludePrinted ? `?excludePrinted=true` : ""}`, { tableId, socketId: getSocketId(), filter: localStorage.getItem('filterRes') })
      .then(({ data: result }) => {
        if (!result || !result.success) {
          //error
          setError(result.error || true);
        } else {
          //success
          if (myTableIndex !== -1) {
            //table already exists -> update
            setTable(tableId, {
              ...myTable,
              reservations: result.reservations,
              isLoading: false,
              isModifing: false,
            });
            if (newTableIndex === -1 && result.reservations.length != 0 && history.location.pathname == '/owner/dashboard') {
              const newOrderSound = new Audio(notificationSoundNewOrder);
              newOrderSound.play();
            }
          } else {
            //table doesnt exists -> add new
            addTable(tableId, result.reservations);
            if (result.reservations.length > 0 && history.location.pathname == '/owner/dashboard') {
              const newOrderSound = new Audio(notificationSoundNewOrder);
              newOrderSound.play();
            }
          }
          setLastGeneralUpdate(new Date());
        }
      });
  };

  const modifyTable = (tableId, value) => {
    axios
      .post("/api/dashboard/owner/table/modify", {
        reservations: value.reservations,
        tableId,
        socketId: getSocketId(),
      })
      .then(({ data }) => {
        if (!data || !data.success) {
          //error
          setError(data.error || true);
        } else {
          //success
          setTable(tableId, value);
        }
      });
  };

  // Questo loading viene gestito nel componente owner.dashboard.table.card.jsx r. 52
  const printTable = (tableId, loading, componentPrint) => {
    axios
      .post("/api/dashboard/owner/table/print", {
        tableId,
        selected_option: localStorage.getItem('filterRes'),
        socketId: getSocketId(),
      })
      .then(({ data }) => {
        if (!data || !data.success) {
          //error
          setError(data.error || true);
        } else {
          removeTable(tableId, true, loading, componentPrint);
        }
      });
  };

  const addTable = (tableId, reservations) => {
    let d = [...data];
    d.push({ tableId, reservations });
    setData(d);
    setLastGeneralUpdate(new Date());
  };

  const removeTable = (tableId, noDbUpdate, loading, componentPrint) => {
    const myTableIndex = data.map(({ tableId }) => tableId).indexOf(tableId);
    const filterRes = localStorage.getItem('filterRes');
    if (myTableIndex !== -1) {
      let d = [...data];
      if (!noDbUpdate) {
        axios
          .post("/api/dashboard/owner/table/delete", {
            tableId,
            socketId: getSocketId(),
          })
          .then(({ data }) => {
            if (!data || !data.success) {
              //error
              setError(data.error || true);
            } else {
              //success
              d.splice(myTableIndex, 1);
              setData(d);
              setLastGeneralUpdate(new Date());
            }
          });
      } else {
        setTimeout(() => {
          //if (filterRes == "bar") {
          //  axios.post(`/api/dashboard/owner/bar/get${excludePrinted ? `?excludePrinted=true` : ""}`, { "selection": "bar" }).then(res => {
          //    setData(res.data.reservations);
          //    if (componentPrint)
          //      loading(false); // Questi loading vengono gestiti nel componente owner.dashboard.table.card.jsx r. 52
          //  });
          //} else if (filterRes === 'cucina') {
          //  axios.post(`/api/dashboard/owner/kitchen/get${excludePrinted ? `?excludePrinted=true` : ""}`, { "selection": "cucina" }).then(res => {
          //    setData(res.data.reservations);
          //    if (componentPrint)
          //      loading(false); // Questi loading vengono gestiti nel componente owner.dashboard.table.card.jsx r.52
          //  });
          //} else {
          axios.post(`/api/dashboard/owner/get${excludePrinted ? `?excludePrinted=true` : ""}`, { filter: localStorage.getItem('filterRes') }).then((res) => {
            setData(res.data.reservations);
            if (componentPrint)
              loading(false); // Questi loading vengono gestiti nel componente owner.dashboard.table.card.jsx r.52
          });
          //}
        }, 1000)
        //setData(d);
        setLastGeneralUpdate(new Date());
      }
    }

  };

  const switchDishStatus = (tableId, dishId) => {
    let [myDish] = getDish(tableId, dishId);
    setDish(tableId, dishId, { ...myDish, loadingStatus: true }); //set loading:true to show loader

    if (myDish) {
      switch (myDish.status) {
        case "preparation":
          myDish.status = "ready";
          break;
        case "ready":
          myDish.status = "completed";
          break;
        case "completed":
          myDish.status = "preparation";
          break;
        default:
          break;
      }
      myDish.loadingStatus = false; //set loading:false to hide loader

      axios
        .post("/api/dashboard/owner/status/set", {
          id: myDish.id,
          status: myDish.status,
          socketId: getSocketId(),
        })
        .then(({ data }) => {
          if (!data || !data.success) {
            //error
            setError(data.error || true);
          } else {
            //success
            setDish(tableId, dishId, myDish);
          }
        });
    }
  };

  const discardNotification = ({ tableId }) => {
    let d = [...data];
    let i = d.findIndex(({ tableId: id }) => id === tableId);
    if (i !== -1) {
      d[i].isCalled = false;
    }
    setData(d);
  };

  const discardCalledTable = (tableId) => {
    socket.emit("discardCalledTable", { tableId, ownerId: user.id });
  };

  return {
    data,
    setData,
    socket,
    setSocket,
    isLoading,
    setIsLoading,
    error,
    setError,
    connected,
    setConnected,
    notification,
    setNotification,
    getSocketId,
    excludePrinted,
    _resToggleFilter,
    getTable,
    setTable,
    getDish,
    setDish,
    modifyTable,
    addTable,
    removeTable,
    switchDishStatus,
    deleteDish,
    restoreDish,
    lastGeneralUpdate,
    ringingBell,
    setRingingBell,
    discardCalledTable,
    printTable,
    updateTable,
  };
};

export const DashboardContext = createContext();

export const DashboardProvider = ({ excludePrinted, ...rest }) => {
  const dashboard = useDashboard(excludePrinted);
  return (
    <>
      <DashboardContext.Provider value={dashboard}>
        {rest.children}
      </DashboardContext.Provider>
    </>
  );
};
